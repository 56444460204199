import React, {useEffect} from "react"
import parse from 'html-react-parser';
import shortid from  "shortid";


const HomeServicesSection = (props) => {
    // const media = matchMedia('(max-width: 991px)')

    // useEffect(() => {
    //     const hero = document.querySelector('[data-hero]')

    //     window.addEventListener('mousemove', (e) => {
    //         const { clientX, clientY } = e
    //         const x = Math.round((clientX / window.innerWidth) * 100)
    //         const y = Math.round((clientY / window.innerHeight) * 100)
        
    //     	hero.style.setProperty('--x', `${x}%`)
    //     	hero.style.setProperty('--y', `${y}%`)
    //     })
    // },[])
  return (
    <section className="services-section">
        <div className="container">
            <div className="row" >
                <div className="col-lg-22">
                    {/* <SlideUp duration={400} delay={500}> */}
                        <h2 className="h2 section-title extra-spacing service-heading-reveal">{props.mainTitle}</h2>
                    {/* </SlideUp> */}
                </div>
            </div>
            {/* <SlideUp duration={800} delay={1000}> */}
                <div className="service-items">
                    <div className="item" >
                        <div className="row">
                            {props.list?.map((object, i) => {
                                return (
                                    <>
                                    <div className="col-lg-11" key={shortid.generate()} >
                                            <p className="extra-large">{parse(object.title)}</p>
                                            {/* <p className="extra-large secondary" aria-hidden={true} data-hero>{parse(object.title)}</p> */}
                                            <ul className={i <= 3 ? "bullet-points" : "bullet-points mb"}>
                                                {object.bulletPoints?.map((object2, j) => {
                                                    return (
                                                        <li key={shortid.generate()}><p className="small">{parse(object2.description)}</p></li>
                                                    )
                                                })}
                                            </ul>
                                    </div>
                                    <div className={((i+2)%2 === 1) ? '' : 'col-lg-2'}></div>
                                    </>
                                )
                            })}
                        </div>
                    </div>
                </div>
            {/* </SlideUp> */}
        </div>
    </section>
  )
}

export default HomeServicesSection